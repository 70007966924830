

.feature-section .single-feature .content p {
    text-align: justify;
}

/* .hero-section .hero-content{ 
    text-align: justify;
} */

.main-img{ 
    /* border: 12px solid black ; */
    width: 140%;
    /* height: 750px;
    object-fit: contain ; */
    position: relative;
    /* box-shadow: 8px 4px 13px 0px #5d3faf4d; */
    /* box-shadow: 8px 4px 13px 0px #58588929; */
    box-shadow: 8px 4px 13px 0px #5757d029;
    left: -31px;
    top:-20px;
    margin: 50px 0px;
    
}
.logo-box{ 
    border: 1px solid #ffffffb0;
    font-weight: 900;
    font-size: 30px;
    background-color: #5864FF;
    color: white;
    padding: 1px 10px;
    border-radius: 2px;
    ;
}
#header_signup{ 
    border: 1px solid white;
    color: #fff;
    
    /* color: #5864FF; */
    /* border-color: #5864FF; */
    right: -100px;
    padding: 5px 18px;
    background: #5864FF;
    /* position: absolute; */
    /* right: -120px; */
    /* right: 60px; */

}


.feature-extended-section .single-feature-extended .content p {
 
    text-align: justify;
}





@media only screen  and (max-width: 1600px) {
    #header_signup{  
        right: -80px; 
 

    }
}


@media only screen  and (max-width: 1500px) {
    #header_signup{  
        right: -40px; 
 

    }
}



@media only screen  and (max-width: 1500px) {
    #header_signup{  
        right: -30px; 
 

    }
}


@media only screen  and (max-width: 993px) {
    #header_signup{  
        display:none;
        /* position: absolute;
        right: 500px;  */

    }
}

.get-start-button-pr{ 
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: flex-start;

}
.no-credit-tl{ 
    color: white;
    margin-top: 5px;
    margin-left: 2px;
    /* font-style: italic;*/
    
}

@keyframes glow {
    0%  { 
        opacity: 0.6;
    }
    25%  {
        opacity: 1; 
    }
    50%  {
        opacity: 0.6; 
    }
    75%  {
        opacity: 1; 
    }
    100%  {
        opacity: 0.6; 
    }
  }
  
  /* The element to apply the animation to */
  .no-credit-tl {
    
    animation-name: glow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }